<template>
  <img width="200" height="200" src="./assets/logo.svg"
       alt="Эмблема Medsenger Storefront"
       title="Эмблема Medsenger Storefront">
  <AppList/>
  <footer>
    <strong>
      <span class="keep-line">© 2025 ООО «ТелеПат».</span> <span class="keep-line">Телемедицина для пациентов.</span>
    </strong>
  </footer>
</template>

<script>
import AppList from './components/AppList.vue'

export default {
  name: 'App',
  components: {
    AppList
  },
}
</script>

<style>
body {
  margin: 0;
}
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #006c88;
  margin-top: 60px;
}
span.keep-line {
  display: inline-block;
}
img {
  max-width: 100%;
}
footer {
  color: white;
  background: #24A8B4;
  padding: 30px 0;
}
</style>
